$(document).ready(function () {
  if (window.location.href.match(/\/stripe\/payment\/*/i)) {
    var container = document.getElementById('payment-container');
    var stripe = Stripe(container.getAttribute('key'));
    var returnUrl = container.getAttribute('return-url');
    var blurMessage = container.getAttribute('blur-message');
    var emptyMessage = container.getAttribute('empty-message');
    var elements = stripe.elements();
    var style = {
      base: {
        padding: '6px 10px',
        color: '#32325d',
        fontSize: '16px',
      },
    };

    // Create an instance of the fpxBank Element.
    var fpxBank = elements.create('fpxBank', {
      style: style,
      accountHolderType: 'individual',
    });

    // Add an instance of the fpxBank Element into the container with id `fpx-bank-element`.
    fpxBank.mount('#fpx-bank-element');

    document
      .getElementById('submit-btn')
      .addEventListener('click', function () {
        var fpxButton = this;
        var clientSecret = fpxButton.dataset.secret;
        this.disabled = true;
        stripe
          .confirmFpxPayment(clientSecret, {
            payment_method: {
              fpx: fpxBank,
            },
            // Return URL where the customer should be redirected after the authorization
            return_url: window.location.origin + returnUrl,
          })
          .then(function (result) {
            if (result.error) {
              // Inform the customer that there was an error.
              var errorElement = document.getElementById('error-message');
              errorElement.style.display = 'block';
              if (
                result.error.code === 'parameter_invalid_empty' &&
                result.error.param === 'payment_method_data[fpx][bank]'
              ) {
                errorElement.textContent = emptyMessage;
              } else {
                errorElement.textContent = result.error.message;
              }
            }
          });
      });

    window.addEventListener(
      'focus',
      function () {
        document.title = 'SHOPLINE';
      },
      true,
    );

    window.addEventListener(
      'blur',
      function () {
        document.title = blurMessage;
      },
      true,
    );
  }
});
