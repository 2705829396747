app.service('currencyLanguageService', [
  function () {
    const languagesByCurrency = {
      default: ['en', 'zh-hant'],
      HKD: ['en', 'zh-hant'],
      TWD: ['en', 'zh-hant'],
      CNY: ['en', 'zh-cn'],
      MYR: ['en', 'ms', 'zh-cn'],
      VND: ['en', 'vi'],
      JPY: ['en', 'ja'],
      KRW: ['en', 'ko'],
      SGD: ['en', 'ms', 'zh-cn'],
      THB: ['en', 'th'],
      EUR: ['en', 'de', 'fr'],
    };

    function getCurrencyLanguage(currency) {
      return languagesByCurrency[currency] || languagesByCurrency['default'];
    }

    return {
      getCurrencyLanguage,
    };
  },
]);
