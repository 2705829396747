import { isFunction } from 'lodash-es';

export const checkTrackInAppJsBridge = () =>
  isFunction(window.SLAppWebInterface?.logGA4Event) ||
  isFunction(window.webkit?.messageHandlers?.slappWebBridge?.postMessage);

/**
 * page trigger event tracking for App webview
 * @param {*} name event name
 * @param {*} params event params
 * @doc https://shopline.yuque.com/xc6gve/ub5nvl/ggi8eg8rvr5t02ie#GAIlK password: wn93
 */
export const triggerAppGA4TrackEvent = (name, params) => {
  if (!name) {
    console.warn('Event name not passed!');
    return;
  }

  if (!checkTrackInAppJsBridge()) {
    return;
  }
  // Call Android interface
  if (window.SLAppWebInterface?.logGA4Event) {
    window.SLAppWebInterface.logGA4Event(name, JSON.stringify(params));
    return;
  }
  // Call iOS interface
  const message = {
    command: 'logGA4Event',
    name,
    parameters: params,
  };
  window.webkit.messageHandlers.slappWebBridge.postMessage(message);
};
