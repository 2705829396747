app.run([
  '$rootScope',
  'trackerService',
  function ($rootScope, trackerService) {
    const { generalEventType } = trackerService;

    const getTracker = () => {
      if (typeof window._lt === 'function') return window._lt;
    };
    const sendAddToCart = () => {
      const ltService = getTracker();
      ltService?.('send', 'cv', { type: 'AddToCart' });
    };
    const sendCheckout = () => {
      const ltService = getTracker();
      ltService?.('send', 'cv', { type: 'Checkout' });
    };
    const sendConversion = () => {
      const ltService = getTracker();
      ltService?.('send', 'cv', { type: 'Conversion' });
    };
    const sendRegistration = () => {
      const ltService = getTracker();
      ltService?.('send', 'cv', { type: 'CompleteRegistration' });
    };
    const sendViewContent = () => {
      const ltService = getTracker();
      ltService?.('send', 'cv', { type: 'ViewContent' });
    };

    $rootScope.$on(generalEventType.VIEW_CONTENT, () => {
      sendViewContent();
    });
    $rootScope.$on(generalEventType.REGISTRATION, () => {
      sendRegistration();
    });
    $rootScope.$on(generalEventType.ADD_TO_CART, () => {
      sendAddToCart();
    });
    $rootScope.$on(generalEventType.LOAD_CHECKOUT_PAGE, () => {
      sendCheckout();
    });
    $rootScope.$on(generalEventType.PURCHASE, () => {
      sendConversion();
    });
  },
]);
